#toTop {
    position: fixed;
    bottom: 5px;
    right: 5px;
    width: 82px;
    height: 33px;
    z-index: 1000;
}

.loading-icon {
 
    animation: animate 1s infinite;
  }
  
  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }