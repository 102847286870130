html.dark-mode {
  filter: invert(95%);
}

html.dark-mode button {
  filter: invert(100%);
}

html.dark-mode img {
  filter: invert(100%);
}

html.dark-mode  .alert-danger {
  filter: invert(100%);
}

html.dark-mode  .alert-success {
  filter: invert(100%);
}
html.dark-mode  .badge {
  filter: invert(100%);
}

